<template>
  <div class="header-block">
    <header class="header-wrap flex flex-col">
      <div class="header_title relative w-full padding_wrap flex flex-col justify-between">
        <div class="logo_wrap flex flex-col items-center">
          <p class="logo_name my-2 md:my-3">Tea<br>Time</p>
          <img class="logo_img" src="../assets/img/Leaf_small.svg" alt="" draggable="false">
        </div>
        <div class="title_wrap flex flex-col justify-center items-left mb-9 sm:mb-12">
          <span>Selection Japon</span>
          <h1 class="line">Thé vert Matcha</h1>
        </div>
      </div>
      <nav class="header_nav container-wrap padding_wrap py-4 sm:py-6">
        <ul class="header_nav_wrapper w-full flex justify-center px-1">
          <li class="header_nav_item w-1/3" :class="{ active: hash === 'presentation'}" @click="nextPage(1)">
            <a href="javascript:void(0);" class="header_nav_item_link">Présentation</a>
          </li>
          <li class="header_nav_item w-1/3" :class="{ active: hash === 'preparation'}" @click="nextPage(2)">
            <a href="javascript:void(0);" class="header_nav_item_link">Préparation</a>
          </li>
          <li class="header_nav_item w-1/3" :class="{ active: hash ==='degustation'}" @click="nextPage(3)">
            <a href="javascript:void(0);" class="header_nav_item_link">Dégustation</a>
          </li>
        </ul>
      </nav>
    </header>
  </div>
</template>

<script>
export default {
  name: 'header-block',
  props: ['hash'],
  data() {
    return {
      page: 1,
    }
  },
  methods: {
    nextPage(id) {
      this.page = id
      console.log(888, id)
      this.$emit('nextPage', id)
    }
  }
}
</script>

<style scoped>
.header_title {
  min-height: 365px;
  overflow: hidden;
  position: relative;
  /*background-color: darkslategray;*/
  background: url("../assets/img/header_bg_1_1280.jpg") no-repeat center;
  background-size: cover;
}

.header_title:before {
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  width: 100%;
  height: 265px;
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 100%);
}

.logo_wrap {
  height: 86px;
  width: 61px;
  border-radius: 0 0 39.5px 39.5px;
  background-color: #000000;
  box-shadow: 0 4px 10px 0 rgba(0,0,0,0.2);
}

/*.header_title:before {*/
/*  position: absolute;*/
/*  content: '';*/
/*  top:0;*/
/*  right: 0;*/
/*  width: 100px;*/
/*  height: 100px;*/
/*  background: url("../assets/img/swipe_arrow_dowm.svg") no-repeat center;*/
/*  background-size: cover;*/
/*}*/

/*.header_title:after {*/
/*  position: absolute;*/
/*  content: '';*/
/*  top:0;*/
/*  right: 0;*/
/*  width: 100px;*/
/*  height: 100px;*/
/*  background: url("../assets/img/swipe-up.svg") no-repeat center;*/
/*  background-size: cover;*/
/*  animation: swipe 2s ease-in infinite, opacity 2s ease-in infinite;*/
/*  !*animation-duration: 2s;*!*/
/*  !*animation-name: swipe;*!*/
/*}*/

/*@keyframes swipe {*/
/*  from {*/
/*    transform: rotate(-50deg);*/
/*  }*/

/*  to {*/
/*    transform: rotate(20deg);*/
/*  }*/
/*}*/

/*@keyframes opacity {*/
/*  from {*/
/*    opacity: 1;*/
/*  }*/
/*  50% {opacity: 1;}*/
/*  75% {opacity: 1}*/
/*  85% {opacity: 0}*/
/*  to {*/
/*    opacity: 0*/
/*  }*/
/*}*/

.title_wrap span {
  font-size: calc(14px + 1.5vw);
  letter-spacing: 0;
  line-height: 1.3;
  text-align: left;
  opacity: 0.75;
  color: rgba(255, 255, 255, 0.75);
  font-family: 'Jost';
}

.header_nav {
  background-color: rgba(255, 255, 255, 0.1);
  z-index: 2;
  /*transform: translateY(-50%);*/
  /*margin-bottom: calc(-20px - 4.3vw);*/
}

.header_nav_item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(30px + 3vw);
  color: #FFFFFF;
  font-size: calc(10px + 1.2vw);
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  border-radius: calc(30px + 2vw);
  box-sizing: border-box;
  /*border: 1px solid #000000;*/
  font-family: 'Jost';
  transition: all 0.2s ease-out;
}

.header_nav_item.active {
  font-family: 'Jost-Medium';
  background-color: #FFFFFF;
  color: #000000;
  box-shadow: 0 4px 10px 0 rgba(0,0,0,0.5);
  transition: all 0.5s ease-out;
}

@media (max-width: 1024px) {
  .container-wrap {
    max-width: 1024px;
  }

  /*.header_nav {*/
  /*  margin-bottom: calc(-25px - 4.3vw);*/
  /*}*/
}

@media (max-width: 768px) {
  .container-wrap {
    max-width: 768px;
  }
}

@media (max-width: 640px) {
  .logo_wrap {
    height: 86px;
    width: 61px;
  }

  .container-wrap {
    max-width: 640px;
  }
}

@media (min-width: 640px) {
  .header_title {
    min-height: calc(165px + 30.5vw);
  }

  .logo_wrap {
    height: calc(70px + 2.5vw);
    width: calc(40px + 2.5vw);
  }
}

@media (max-width: 390px) {
  .title_wrap span {
    font-size: 16px;
    line-height: 23px;
  }

  .header_nav_item {
    height: 38px;
    border-radius: 22.5px;
    font-size: 14px;
  }
}

</style>
