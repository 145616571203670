<template>
  <div class="presentation container-wrap">
    <div class="presentation_top_block padding_wrap all border_bottom relative flex flex-col items-start">
      <h2 class="mb-5 md:mb-7">Arôme</h2>
      <p>Pamplemousse, orange, citron, parsemée de gingembre, de pétales de pivoine et de fleurs bleues</p>
      <div class="presentation_top_img_wrap w-full flex justify-between">
        <div class="presentation_top_img-item w-1/3 flex justify-start items-center">
          <img class="presentation_top_img" src="../assets/img/presentation_img_top_1.png" alt="" draggable="false">
        </div>
        <div class="presentation_top_img-item w-1/3 flex justify-end items-center">
          <img class="presentation_top_img" src="../assets/img/presentation_img_top_2.png" alt="" draggable="false">
        </div>
        <div class="presentation_top_img-item w-1/3 flex justify-end items-center">
          <img class="presentation_top_img" src="../assets/img/presentation_img_top_3.png" alt="" draggable="false">
        </div>
      </div>
    </div>
    <div class="presentation_center_block padding_wrap all border_bottom relative flex flex-col items-start">
      <h2 class="mb-5 md:mb-7">Origine</h2>
      <div class="presentation_center_img_wrap relative w-full flex justify-center items-center">
        <img class="presentation_center_img" src="../assets/img/presentation_map.png" alt="" draggable="false">
        <p class="map-text absolute mb-0">Japon, Ibaraki prefecture</p>
      </div>
    </div>
    <div class="presentation_bottom_block flex flex-col items-start">
      <div class="presentation_bottom_block_wrap_text padding_wrap all flex flex-col items-start">
        <h2 class="mb-5 md:mb-7">Histoire</h2>
        <p class="mb-0">Ce thé vert est une création exceptionnelle de TeaTime rendue possible par la magie du terroir Japoanis et le savoir-faire méticuleux
          et passionné du planteur du jardin d’Ibaraki. </p>
      </div>
      <div class="presentation_bottom_block_wrap_img full_page_img img_1">
      </div>
      <div class="presentation_bottom_block_wrap_text padding_wrap all flex flex-col items-start">
        <p class="mb-0">Les splendides feuilles de la récolte d'été, très volumineuses, sont semblables à celles d'un Thé Vert d’Hokaido :
          roulées une à une à la main, elles ont conservé un aspect végétal original et inédit.</p>
      </div>
      <div class="presentation_bottom_block_wrap_img full_page_img img_2">
      </div>
      <div class="presentation_bottom_block_wrap_text padding_wrap all flex flex-col items-start">
        <p>Leur parfum de mangoustan mûr est particulièrement odorant.</p>
        <p class="line pb-4 mb-0">La liqueur ambrée, très sensuelle, donne une tasse pétillante, vive, un goût long en bouche mais tout en douceur,
          qui évoque des fruits exotiques équilibrés d'une pointe d'agrumes.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "presentation"
}
</script>

<style scoped>


.presentation_center_img_wrap:before {
  position: absolute;
  content: '';
  height: 12px;
  width: 12px;
  background-color: #B0D85B;
  box-shadow: 4px 4px 10px 0 rgba(0,0,0,0.5);
  z-index: 5;
  border-radius: 50%;
  left: 54%;
  bottom: 33.5%;
}
/*.presentation_top_img-item {*/
/*  justify-content: center;*/
/*}*/
.presentation_top_img {
  object-fit: contain;
  max-height: calc(40px + 7.5vw);
}

.presentation_bottom_block_wrap_img {
  width: 100%;
}

.presentation_bottom_block_wrap_img.img_1 {
  background: url("../assets/img/presentation_bottom_img_1_1280.jpg") no-repeat center;
  background-size: cover;
}

.presentation_bottom_block_wrap_img.img_2 {
  background: url("../assets/img/presentation_bottom_img_2_1280.jpg") no-repeat center;
  background-size: cover;
}

@media (min-width: 768px) {
  .presentation_top_img-item {
    justify-content: center;
  }
}

@media (min-width: 640px) {
  .presentation_top_img-item:nth-child(2) {
    justify-content: center;
  }

  .presentation_center_img_wrap:before {
    height: calc(7px + 1.2vw);
    width: calc(7px + 1.2vw);
    left: 54%;
    bottom: 33%;
  }
}

@media (max-width: 390px) {
  .presentation_top_img {
    max-height: 72px;
  }
}
</style>