<template>
  <div class="degustation container-wrap">
    <div class="degustation_top_block padding_wrap all border_bottom relative flex flex-col items-start">
      <h2 class="mb-5 md:mb-7">Quand ?</h2>
      <div class="degustation_top_img_wrap w-full flex justify-between">
        <div class="degustation_top_img-item w-1/2 flex-col flex justify-start items-center">
          <img class="degustation_top_img mb-5 md:mb-10" src="../assets/img/DayNight.svg" alt="" draggable="false">
          <span class="title_img">Toute heure<br> de la journée</span>
        </div>
        <div class="degustation_top_img-item w-1/2 flex flex-col justify-start items-center">
          <img class="degustation_top_img mb-5 md:mb-10" src="../assets/img/TeaTimer.svg" alt="" draggable="false">
          <span class="title_img">4 à 5 fois <br>par jour</span>
        </div>
      </div>
    </div>
    <div class="degustation_bottom_block relative flex flex-col items-start">
      <div class="degustation_bottom_block_wrap_text padding_wrap all flex flex-col items-start">
        <h2 class="mb-5 md:mb-7">Avec quoi ?</h2>
        <p>Chocolat au lait, chocolat blanc</p>
        <span class="title_img">Cet encas gourmand a tremper dans votre thé vous séduira à coup sûr</span>
      </div>
      <div class="degustation_bottom_block_wrap_img full_page_img img_1 w-full">
      </div>
      <div class="degustation_bottom_block_wrap_text padding_wrap all flex flex-col items-start">
        <p>Mochi</p>
        <span class="title_img">Cette somptueuse douceur japonaise se marie parfaitement avec le thé vert</span>
      </div>
      <div class="degustation_bottom_block_wrap_img full_page_img img_2 w-full">
      </div>
      <div class="degustation_bottom_block_wrap_text padding_wrap all flex flex-col items-start">
        <p>Chiffon cake</p>
        <span class="title_img">Léger et aérien, il vous laissera en bouche une délicieuse association</span>
      </div>
      <div class="degustation_bottom_block_wrap_img full_page_img img_3 w-full"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "degustation"
}
</script>

<style scoped>
.degustation_top_img-item span.title_img {
  color: #B0D85B;
}

.degustation_bottom_block_wrap_text span.title_img {
  text-align: left;
}

.degustation_bottom_block_wrap_img.img_1 {
  background: url("../assets/img/degustation_img_1_1280.jpg") no-repeat center;
  background-size: cover;
}

.degustation_bottom_block_wrap_img.img_2 {
  background: url("../assets/img/degustation_img_2_1280.jpg") no-repeat center;
  background-size: cover;
}

.degustation_bottom_block_wrap_img.img_3 {
  background: url("../assets/img/degustation_img_3_1280.jpg") no-repeat left center;
  background-size: cover;
  margin-bottom: 30px;
}

@media (min-width: 640px) {
  .degustation_bottom_block_wrap_img.img_3 {
    margin-bottom: 40px;
  }
}

</style>