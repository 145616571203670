<template>
  <div class="preparation container-wrap">
    <div class="preparation_top_block padding_wrap all border_bottom relative flex flex-col items-start">
      <h2 class="mb-5 md:mb-7">Infusion</h2>
      <div class="preparation_top_img_wrap w-full flex justify-between">
        <div class="preparation_top_img-item w-1/2 flex-col flex justify-start items-center">
          <img class="preparation_top_img mb-2" src="../assets/img/Timer.svg" alt="" draggable="false">
          <span class="title_img">Temps</span>
          <span class="number">2’</span>
        </div>
        <div class="preparation_top_img-item w-1/2 flex flex-col justify-start items-center">
          <img class="preparation_top_img mb-2" src="../assets/img/Temperature.svg" alt="" draggable="false">
          <span class="title_img">Temperature</span>
          <span class="number">70°</span>
        </div>
      </div>
    </div>
    <div class="preparation_center_block border_bottom relative flex flex-col items-start">
      <div class="preparation_center_block_wrap_text padding_wrap all flex flex-col items-start">
        <h2 class="mb-5 md:mb-7">Astuce</h2>
        <p class="mb-0">Pour une qualité nutritionnelle : pour favoriser les catéchines et les EGCG il faut faire infuser le thé a 70° et laisser infuser 2 minutes</p>
      </div>
      <div class="preparation_center_block_wrap_img full_page_img img_1 w-full">
      </div>
      <div class="preparation_center_block_wrap_text padding_wrap all flex flex-col items-start">
        <p class="mb-0">Pour adoucir le thé : rajouter du miel (éviter le lait, qui fait baisser la capacité antioxydante du thé)</p>
      </div>
      <div class="preparation_center_block_wrap_img img_2 w-full">
      </div>
      <div class="preparation_center_block_wrap_text padding_wrap all flex flex-col items-start">
        <p>Effet stimulant ou effet excitant ?</p>
        <p>La majorité de la caféine est extraite des 2 a 3 minutes a 70°, il faut le boire à ce moment la pour un effet excitant.</p>
        <p class="line pb-4 mb-0">Pour un effet stimulant sur le long terme, il faudra le laisser infuser quelques minutes de plus.</p>
      </div>
    </div>
    <div class="preparation_bottom_block padding_wrap all relative flex flex-col items-start">
      <div class="preparation_bottom_block_item border_all p-5 pt-14 relative flex flex-col items-center">
        <div class="preparation_bottom_block_img">
          <img class="preparation_bottom_img px-6" src="../assets/img/TeaPot.svg" alt="" draggable="false">
        </div>
        <p class="mb-0">Ne jamais faire bouillir l’eau (jamais supérieur a 90°) une température trop élevée détruit les composés aromatiques du thé (notes de tete),
          aussi certains composés comme l’acétaldéhyde sont détruits a partir de 70°, en bouillant l’eau perd une partie de son oxygène,
          qui intéragit avec certains composés aromatiques afin de favoriser leur perception.</p>
      </div>
      <div class="preparation_bottom_block_item border_all p-5 pt-14 relative flex flex-col items-center">
        <div class="preparation_bottom_block_img">
          <img class="preparation_bottom_img px-6" src="../assets/img/Water.svg" alt="" draggable="false">
        </div>
        <p class="mb-0">Privilégier de l’eau filtrée par carafe ou en bouteille pour un PH le plus neutre possible, et éviter l’eau du robinet trop chlorée et riche en calcaire.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "preparation"
}
</script>

<style scoped>
/*.preparation_top_img-item span.title_img {*/
/*  font-family: Jost-Light;*/
/*  font-size: 18px;*/
/*  font-weight: 300;*/
/*  letter-spacing: 0;*/
/*  line-height: 1.3;*/
/*  text-align: center;*/
/*}*/

.preparation_top_img-item span.number {
  color: #B0D85B;
  font-family: Jost-Light;
  font-size: 35px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 1.4;
  text-align: center;
}

.preparation_center_block_wrap_img.img_1 {
  /*height: 220px;*/
  background: url("../assets/img/preparation_img_1_1280.jpg") no-repeat right 50% top 60%;
  background-size: cover;
}

.preparation_center_block_wrap_img.img_2 {
  height: 250px;
  background: url("../assets/img/preparation_img_2_1280.jpg") no-repeat top;
  background-size: cover;
}

.preparation_bottom_block {
  padding-top: 0!important;
}

.preparation_bottom_block_item p {
  font-family: Jost;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 1.3;
  text-align: justify;
}

.preparation_bottom_block_item {
  margin-top: 75px;
}

.preparation_bottom_block_img {
  top:-30px;
  position: absolute;
  background-color: #000000;
  max-height: 60px;
}


@media (min-width: 640px) {
  /*.preparation_top_img-item span.title_img {*/
  /*  font-size: calc(8px + 1.6vw);*/
  /*}*/

  .preparation_top_img-item span.number {
    font-size: calc(22px + 1.6vw);
  }

  /*.preparation_center_block_wrap_img.img_1 {*/
  /*  height: calc(100px + 30.5vw);*/
  /*}*/

  .preparation_center_block_wrap_img.img_2 {
    height: calc(130px + 30.5vw);
    background-position: right 50% top 20%;
  }

  .preparation_bottom_block_item p {
    font-size: calc(6px + 1.6vw);
  }
}

/*@media (min-width: 490px) {*/
/*  .preparation_center_block_wrap_img.img_2 {*/
/*    background-position: right 50% top 20%;*/
/*  }*/
/*}*/

</style>